import React from 'react';
import SEO from '../components/seo';

const NotFoundPage = ({ location }) => (
  <>
    <SEO title='404: Not found' />
    <div css={{ height: '100vh' }}>
      <h1 align='center'>404: Not Found</h1>
      <p align='center'>Halaman yang anda cari tidak ditemukan.</p>
    </div>
  </>
);

export default NotFoundPage;
